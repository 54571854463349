<template>
  <BaseConfirmDialog
    :isLoading="isLoading"
    :value="value"
    :disabled="disableConfirm"
    @input="$emit('input', $event)"
    @confirm="$emit('confirm')"
  >
    <div data-testid="end-trial-dialog-content">
      <span class="d-block">Trial status</span>
      Changed from
      <strong>{{ $t('patient-trial-status.' + patient.currentState) }}</strong>
      to
      <strong>
        {{ $t('patient-trial-status.completed') }}
      </strong>

      <WarningBox expand class="mt-2 mb-1">
        Please ask the patient to complete a final data transfer from the app
        and finalise any remaining tasks.

        <div class="mt-6">
          Enter patient ID to continue

          <v-text-field
            v-model="confirmPatientId"
            placeholder="Patient ID"
            outlined
            :rules="patientIdRules"
            :error-messages="patientNumberErrors"
            inputmode="numeric"
            class="rounded-lg small-text-field mt-2"
            background-color="#FFFFFF"
            dense
            data-testid="end-trial-dialog-input"
            @keypress="allowNumbersOnly($event)"
          />
        </div>
      </WarningBox>
    </div>
  </BaseConfirmDialog>
</template>

<script>
import BaseConfirmDialog from '../_BaseConfirmDialog'
import WarningBox from '@/components/WarningBox'

import allowNumbersOnlyMixin from '@/components/mixins/allowNumbersOnlyMixin'
import isNumeric from '@/utils/isNumeric'

export default {
  name: 'EndTrialDialog',
  mixins: [allowNumbersOnlyMixin],
  props: {
    value: { type: Boolean, required: true },
    patient: { type: Object, required: true },
    isLoading: { type: Boolean, required: true },
  },
  components: { BaseConfirmDialog, WarningBox },
  data() {
    return {
      confirmPatientId: null,
      patientIdRules: [v => !!v || 'Patient ID is required.'],
    }
  },
  computed: {
    patientNumberErrors() {
      let v = this.confirmPatientId
      if (!v) return []

      if (!isNumeric(v) || v.length !== 6)
        return ['The patient ID must be 6 digits']

      if (v && this.patient.patientNumber !== v) {
        return [
          `Patient ID does not match. Please ensure to enter the correct patient ID.`,
        ]
      }

      return []
    },
    disableConfirm() {
      return !this.confirmPatientId || this.patientNumberErrors.length > 0
    },
  },
}
</script>

<style lang="scss" scoped>
.small-text-field {
  width: 240px;
  font-size: 14px;
}
</style>
